@import 'raster.grid.css';

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-ExtraLightItalic.otf')
    format('opentype');
  font-weight: 200;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

@font-face {
  font-family: 'Raleway';
  src: url('/images/static/Raleway-v4020-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on;
}

:root {
  --red-color: #e22525;
  --blue-color: rgb(33, 4, 247);
  --pink-color: rgb(250, 229, 229);

  --nc-font-sans: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --nc-font-mono: Consolas, monaco, 'Ubuntu Mono', 'Liberation Mono',
    'Courier New', Courier, monospace;
  --nc-tx-1: #000;
  --nc-tx-2: #1a1a1a;
  --nc-bg-1: #fff;
  --nc-bg-2: #f6f8fa;
  --nc-bg-3: #e5e7eb;
  --nc-lk-1: #0070f3;
  --nc-lk-2: #0366d6;
  --nc-lk-tx: #fff;
  --nc-ac-1: rgb(250, 229, 229);
  --nc-ac-tx: #e22525;

  @media (prefers-color-scheme: dark) {
    --nc-tx-1: #000;
    --nc-tx-2: #1a1a1a;
    --nc-bg-1: #fff;
    --nc-bg-2: #f6f8fa;
    --nc-bg-3: #e5e7eb;
    --nc-lk-1: #0070f3;
    --nc-lk-2: #0366d6;
    --nc-lk-tx: #fff;
    --nc-ac-1: rgb(250, 229, 229);
    --nc-ac-tx: #e22525;
  }
}

body {
  font-weight: 500;
  font-feature-settings: 'salt' on, 'ss03' on, 'ss05' on, 'ss07' on, 'ss08' on,
    'ss09' on, 'ss10' on, 'ss02' on;
}

code,
samp,
kbd,
pre {
  background: none;
  border: none;
}

h1,
nav,
footer {
  text-align: center;
}

h1,
h2,
h3 {
  border-bottom: none;
}

a {
  color: var(--red-color);
  text-decoration: none;

  &:hover {
    color: var(--red-color);
    text-decoration: underline;
  }
}

img.lazy {
  &:not(.loaded) {
    filter: blur(3px);
  }
}

nav {
  display: flex;
  justify-content: space-between;
  margin: -20px;
  position: fixed;
  top: 0;
  width: calc(100% - 0px);
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;

  &.blurred {
    a {
      filter: blur(10px);

      &:hover {
        filter: none;
      }
    }
  }

  &:not(.blurred) {
    a {
      filter: none;

      &:hover {
        filter: blur(10px);
      }
    }
  }

  a {
    font-size: 35px;
    padding: 20px;
    text-transform: uppercase;
    transition: filter 0.125s cubic-bezier(0.68, -0.01, 0.19, 0.65);
    transition: filter 0.25s linear;

    &.current {
      filter: none;
    }

    &:hover {
      text-decoration: none;
    }

    &.preview {
      width: 100%;
    }
  }

  @media screen and (max-width: 730px) {
    display: none;
  }
}

nav-icons {
  align-items: baseline;
  display: none;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;

  img {
    cursor: pointer;

    &:hover {
      // transform: scale(1.1);
    }

    &:active {
      // transform: scale(0.9);
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    position: sticky;
    top: 20px;
    background-color: white;
  }
}

nav-menu {
  align-items: right;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  height: 0;
  user-select: none;
  -webkit-user-select: none;
  transition: height 0.125s linear;
  transition: opacity 0.25s linear;
  position: fixed;
  top: 57px;
  background-color: white;
  width: 100%;
  margin: 0 -20px;
  padding: 0 20px;
  z-index: 1000;

  a {
    font-size: 40px;
    text-transform: uppercase;
    display: none;

    &:last-child {
      margin-bottom: 145px;
    }
  }

  &.open {
    height: calc(100vh - 57px);
    display: flex;

    a {
      display: block;
    }
  }
}

search-box {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 40px);

  input {
    background: none;
    border: none;
    border-bottom: 1px var(--red-color) solid;
    border-radius: 0;
    width: calc(100% - 100px);
    font-size: 20px;
    color: var(--red-color);
    outline: none;
    position: fixed;
    text-transform: uppercase;
    z-index: 1020;
    top: 0px;
    padding: 15px 0px;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  search-results {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    justify-content: space-around;

    a {
      font-size: 30px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 80px 0;
    }
  }
}

footer {
  z-index: 1000;
  user-select: none;
  -webkit-user-select: none;

  svg {
    bottom: -130px;
    position: fixed;
    right: 0;
    transition: all 0.5s linear;
    width: 300px;
    z-index: 999;
  }

  #kid-links {
    transition: all 0.5s linear;
    opacity: 0;
  }

  &:hover {
    svg {
      bottom: -70px;
    }

    #kid-links {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

body {
  hyphens: auto;
  max-width: none;
  padding: 20px;
  transition: background-color 0.5s ease-in-out;

  nav,
  footer {
    transition: opacity 0.5s ease-in-out;
    z-index: 100;
  }

  &.index,
  &.about {
    background-color: var(--red-color);
    color: white;
    font-weight: 500;
    font-size: 26px;

    nav,
    mobile-bar,
    footer {
      display: none !important;
    }

    nav-icons {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    head-nav {
      opacity: 0;
    }

    footer-info {
      display: none;
    }

    a {
      color: white;
      text-decoration: none;
    }

    h1 {
      display: none !important;
    }

    index-info-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      hyphens: none;
      flex-direction: column;

      img {
        height: 80vh;
      }
    }

    index-info-footer-text {
      position: fixed;
      bottom: 15px;
      text-align: center;
    }

    index-info-controls {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      width: 400px;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    index-info-controls-button {
      img {
        height: 17px;
        filter: brightness(8.5);
      }
    }

    index-info-alt-control {
      a {
        color: var(--red-color);
        font-weight: 600;
        font-size: 35px;
        position: fixed;
        right: 0;
        top: 0;
        padding: 0 20px;
      }
    }

    index-info {
      display: flex;
      align-items: center;
      max-width: 400px;
      margin-bottom: 2em;
    }

    index-info-footer {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 600px) {
      font-size: 22px;
    }

    .fly {
      position: fixed;
      width: 60px;
      height: 73px;
      background: url('/images/myxa.svg') no-repeat center center;
      background-size: contain;
      left: calc(100% * var(--x));
      top: calc(100% * var(--y));
    }

    /* Random position */
    .fly {
      --x: calc(var(--x, 0) + calc(1 / 3));
      --y: calc(var(--y, 0) + calc(1 / 3));
    }

    @media screen and (min-width: 640px) {
      .fly {
        --x: calc(var(--x, 0) + calc(1 / 4));
        --y: calc(var(--y, 0) + calc(1 / 4));
      }
    }

    @media screen and (min-width: 1024px) {
      .fly {
        --x: calc(var(--x, 0) + calc(1 / 5));
        --y: calc(var(--y, 0) + calc(1 / 5));
      }
    }

    @keyframes fly-around {
      0% {
        transform: translate(0, 0);
        animation-timing-function: ease-in-out;
      }
      5%,
      60%,
      100% {
        transform: translate(0, 0);
        animation-timing-function: ease-in-out;
      }
      10% {
        transform: translate(50px, -20px) rotate(20deg);
        animation-timing-function: ease-out;
      }
      20% {
        transform: translate(100px, 0px) rotate(40deg);
        animation-timing-function: ease-in-out;
      }
      30% {
        transform: translate(150px, 20px) rotate(60deg);
        animation-timing-function: ease-in-out;
      }
      40% {
        transform: translate(220px, 40px) rotate(80deg);
        animation-timing-function: ease-in-out;
      }
      50% {
        transform: translate(240px, 20px) rotate(60deg);
        animation-timing-function: ease-in-out;
      }
      70% {
        transform: translate(260px, -20px) rotate(40deg);
        animation-timing-function: ease-in-out;
      }
      80% {
        transform: translate(350px, 0px) rotate(20deg);
        animation-timing-function: ease-in;
      }
      90% {
        transform: translate(400px, 20px) rotate(0deg);
        animation-timing-function: ease-in;
      }
    }
  }

  &.about {
    background-color: white;
    color: var(--red-color);

    index-info-controls {
      display: none;
    }

    index-info {
      margin-top: 1em;
    }
  }

  &.head {
    background-color: black;
    user-select: none;
    -webkit-user-select: none;

    nav,
    footer {
      display: none !important;
    }

    nav-icons {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    head-nav {
      opacity: 0;
    }

    footer-info {
      display: none;
    }

    h1 {
      display: none !important;
    }

    img {
      pointer-events: none;
    }

    &:hover {
      head-nav {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      pointer-events: none;
      background: radial-gradient(
        circle 10vmax at var(--cursorX) var(--cursorY),
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 80%,
        rgba(0, 0, 0, 1) 100%
      );
      margin: -20px 0;
    }
  }

  &.visuals {
    background-color: white;

    &.dimmed {
      background-color: black;
    }
  }

  &.page {
    color: var(--red-color);
    font-size: 16px;
    text-align: center;

    content {
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100vh - 180px);
    }

    p {
      margin-block-end: 1em;
    }

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  &.article,
  &.visual,
  &.page {
    color: var(--red-color);

    content {
      margin: 175px auto 0;

      @media screen and (max-width: 600px) {
        margin: auto;
      }
    }

    article-header {
      margin-top: 200px;
      margin-bottom: 200px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 600px) {
        margin-bottom: 0;
        margin-top: 88px;
      }
    }

    h1 {
      color: var(--red-color);
      display: flex;
      font-size: 132px;
      font-weight: bold;
      justify-content: center;
      text-transform: capitalize;
      width: 100%;
      margin-bottom: 30px;
      letter-spacing: -0.075em;
    }

    h2 {
      color: var(--red-color);
      display: flex;
      font-size: 20px;
      font-weight: bold;
      justify-content: center;
      width: 100%;
      margin-top: 0px;
    }
  }

  &.page {
    h1 {
      position: absolute;
      top: 20px;
      width: calc(100% - 40px);
    }
  }

  &.article,
  &.visual {
    .cover-image {
      background-size: cover;
      background-position: 50%;
      min-height: calc(100vh - 34px);
      min-height: --webkit-fill-available;
      display: block;
      min-width: calc(100vw);
      margin: -193px 0px 115px -20px;
    }

    img.cover-non-full {
      display: none;
      min-width: 100vw;
      margin: -20px;
    }

    #article-content {
      a,
      p,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        hanging-punctuation: first;
      }

      a {
        text-decoration: underline;
      }

      img {
        text-align: center;
        width: 100%;
        margin: 0.75rem 0;
      }

      figure {
        margin: 0.75rem 0;

        figcaption {
          font-size: 15px;
          font-weight: 400;
        }
      }

      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        margin: 1rem 0;
      }

      blockquote {
        border: 1px solid;
        border-left: 5px solid var(--red-color);
        background-color: transparent;
      }

      .article-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .overlay {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        visibility: hidden;

        img {
          width: auto;
          max-height: 100vh;
          margin: 0;
        }

        &:target {
          visibility: visible;
          outline: none;
          cursor: zoom-out;
        }
      }

      margin: -115px -20px -41px -20px;
      padding-bottom: 91px;
      padding-top: 7em;
    }

    @media screen and (max-width: 600px) {
      #article-content {
        margin-top: 20px;
        padding-top: 1.5em;
      }
    }
  }
}

mobile-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: space-between;
  background-color: white;
  padding: 0;
  z-index: 1000;

  img {
    height: 17px;
    padding: 20px;
    margin: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 730px) {
    display: flex;
  }
}

r-grid.post-previews {
  margin: -5px;
  margin-top: 40px;

  r-cell.post-preview {
    align-items: center;
    display: flex;
    font-size: 30px;
    justify-content: center;
    // background-color: var(--pink-color);
    margin: 5px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.125s linear;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
    }

    &.dimmed {
      // filter: blur(4px);
    }

    a {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 25px;
      transition: all 0.125s linear;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;

      img {
        align-self: center;
        padding: 0 0.25em;
        transition: all 0.125s linear;
        width: 10em;
        z-index: 1;
        position: absolute;
        opacity: 0;
      }

      post-preview-word-1 {
        transition: all 0.125s linear;
        z-index: 2;
        opacity: 1;
      }

      &:hover {
        text-decoration: none;

        img {
          opacity: 1;
        }

        post-preview-word-1 {
          opacity: 0;
        }
      }

      &:active {
        // transform: scale(0.98);
      }
    }

    @media screen and (max-width: 600px) {
      margin-top: 5px !important;

      a {
        post-preview-word-1 {
          opacity: 1;
        }

        img {
          opacity: 0;
        }

        &:hover {
          img {
            opacity: 0;
          }

          post-preview-word-1 {
            opacity: 1;
          }
        }
      }
    }
  }
}

r-grid.visual-previews {
  margin: -5px;
  margin-top: 40px;

  r-cell.visual-preview {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 600px) {
      margin-top: 5px !important;
    }

    &.dimmed {
      opacity: 0.2;
    }

    &.zoomed {
      img {
        transition: all 0.5s linear;
        transform: scale(2) rotate(7deg);
        filter: contrast(0.2) brightness(2);
      }
    }

    a {
      align-items: center;
      display: flex;
      justify-content: center;
      // height: 100%;
      // width: 100%;

      img {
        align-self: center;
        transition: all 0.5s ease-in-out;
        z-index: 2;
        height: 100%;
        width: 100%;

        &:hover {
          filter: brightness(1.1);
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

article-previews {
  display: block;

  article-preview {
    display: block !important;
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;
    text-transform: uppercase;
    transition: transform 0.125s linear;

    &:hover {
      // transform: scale(1.02);
    }

    &:active {
      // transform: scale(0.98);
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        align-self: center;
        max-width: 1.5em;
        height: auto;
        padding: 0 0.25em;
        margin-bottom: 0;
        visibility: visible;
        z-index: 2;
      }

      &:hover {
        text-decoration: none;

        img {
          visibility: visible;
        }
      }
    }

    @media screen and (max-width: 600px) {
      font-size: 30px;

      a {
        font-size: 20px;

        article-preview-word {
          z-index: 10;
        }

        img {
          max-width: 5em;
        }
      }
    }
  }
}

.swup-transition-main {
  transition: opacity 0.25s;
}

.swup-progress-bar {
  background-color: var(--red-color);
}

bridgetown-search-form {
  input {
    background: var(--pink-color);
    border: none;
    border-radius: 0;
    color: var(--red-color);
    display: flex;
    font-size: 30px;
    font-weight: 400;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0;
    position: fixed;
    text-transform: uppercase;
    top: 100px;
    width: calc(100% - 60px);

    &:focus {
      outline: none;
    }
  }

  bridgetown-search-results::part(inner) {
    ul {
      a {
        li {
          h1 {
            color: var(--red-color);
          }

          p {
            display: none;
          }
        }
      }
    }
  }
}

head-nav {
  svg {
    margin-top: -32px;
    max-height: 90vh;
    width: 100%;

    polygon {
      transition: all 0.1s ease-in-out;

      &:hover {
        // fill: yellow;
        // fill-opacity: 0.1;
      }

      &:active {
        // fill: black;
        // fill-opacity: 0.1;
      }
    }
  }
}

locale-select {
  font-size: 200px;
  height: calc(100vh);
  display: none;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 1);
  margin: 0px 0px -20px -20px;

  a {
    text-align: center;
    filter: blur(10px);
    transition: filter 0.125s cubic-bezier(0.68, -0.01, 0.19, 0.65);
    transition: filter 0.25s linear;
    padding: 20px;

    &:hover {
      filter: none;
      text-decoration: none;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 70px;

    a {
      filter: none;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

sounds-player {
  max-height: 95vh;
}

head-info {
  position: fixed;
  bottom: 10px;
  color: var(--red-color);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-size: 15px;
  line-height: 140%;
  font-weight: 500;
  margin-left: -20px;
}

footer-info {
  position: static;
  bottom: 10px;
  color: var(--red-color);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: calc(100% + 40px);
  font-size: 15px;
  line-height: 140%;
  font-weight: 500;
  margin-left: -20px;
  user-select: none;
  -webkit-user-select: none;
}

error-message {
  font-size: 300px;
  height: calc(100vh + 80px);
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: white;
  margin: -60px 0px -20px -20px;
  display: flex;
  user-select: none;
  -webkit-user-select: none;

  h1 {
    text-align: center;
    filter: blur(25px);
    transition: filter 0.125s cubic-bezier(0.68, -0.01, 0.19, 0.65);
    transition: filter 0.25s linear;
    padding: 20px;
    font-size: 380px;
    color: var(--red-color);
  }

  error-message-top {
    color: var(--red-color);
    position: fixed;
    font-size: 16px;
    top: 35px;
    padding: 2em;
    text-align: center;
  }

  error-message-bottom {
    color: var(--red-color);
    position: fixed;
    font-size: 16px;
    bottom: 15px;
    padding: 1em;
    text-align: center;
  }
}

model-viewer {
  width: 100%;
  height: 95vh;
  background-color: #ffffff;
  margin-bottom: 35px;
}

.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5),
    0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  background-color: rgba(255, 255, 255, 0.9);
  width: 0%;
  height: 100%;
  border-radius: 25px;
  float: left;
  transition: width 0.3s;
}

#ar-button {
  background-image: url(/images/uploads/ar_icon.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 16px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #dadce0;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 60px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status='session-started'] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}
